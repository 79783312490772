import { Checkbox, Row } from 'antd';

import MultiLanguageInput from '@Components/BasicComponents/MultiLanguageInput';
import Validate from '@Components/Validate';

import useSeoSync from '@/hooks/useSeoSync';

import {maxChars, maxKeywords } from '@/utils/errors/list';


export default function SEOInformation ({hasKeywords = true, selector}) {
	const {syncSeo, setSyncSeo, seoTitle, seoDescription, seoKeywords, setValue, countryCode, setCountryCode} = useSeoSync({selector});

	const handleChange = (param) => {
		return ({countryCode, value}) => setValue({param, countryCode, value});
	};

	return (
		<>
			<Row justify="space-between" align="middle">
				<h3>SEO Information</h3>
				<Checkbox checked={syncSeo} onChange={(e) => setSyncSeo(e.target.checked)}>
					Copy from main information
				</Checkbox>
			</Row>
			<Validate requirements={[maxChars(60, {isWarning: true})]}>
				<MultiLanguageInput
					title="SEO title (max. 60 characters)"
					value={seoTitle}
					onChange={handleChange('seoTitle')}
					style={{marginVertical: 10}}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					disabled={syncSeo}
				/>
			</Validate>

			<br />
			<Validate requirements={[maxChars(160, {isWarning: true})]}>
				<MultiLanguageInput
					title="SEO description (max. 160 characters)"
					textArea
					value={seoDescription}
					onChange={handleChange('seoDescription')}
					countryCode={countryCode}
					setCountryCode={setCountryCode}
					disabled={syncSeo}
				/>
			</Validate>
			{hasKeywords &&
				<>
					<br />
					<Validate requirements={[maxKeywords(15)]}>
						<MultiLanguageInput
							title="SEO keywords (max. 15 keywords)"
							value={seoKeywords}
							countryCode={countryCode}
							setCountryCode={setCountryCode}
							onChange={handleChange('seoKeywords')} />
					</Validate>
				</>
			}
		</>
		
	);
}